import React, { useEffect, useState } from "react";
import "../css/Refer.module.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "../Components/Header";
import copy from "copy-to-clipboard";

// import 'remixicon/fonts/remixicon.css'

const Refer = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [user, setUser] = useState();
  const Cashheader = () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    axios
      .get(baseUrl + `me`, { headers })
      .then((res) => {
        setUser(res.data);
        // console.log(res.data);
        Allgames(res.data?.referralCode);
      })
      .catch((e) => {
        alert(e.msg);
      });
  };

  const [cardData, setGame] = useState([]);

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    await axios
      .get(baseUrl + `referral/code/${id}`, { headers })
      .then((res) => {
        console.log(res.data.message);
        if (res.data.message == "User data count not found.") {
          return setGame(0);
        }
        setGame(res?.data);
        console.log(res?.data);
      });
  };

  const [WebSitesettings, setWebsiteSettings] = useState("");
  const fetchData = async () => {
    const response = await fetch(baseUrl + "settings/data");
    const data = await response.json();
    return setWebsiteSettings(data);
  };

  useEffect(() => {
    Cashheader();
    fetchData();
    //eslint-disable-next-line
  }, []);

  const copyCode = (e) => {
    // console.log(Game.roomCode);
    navigator.clipboard.writeText(user?.referralCode);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "referral Code Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  if (user === undefined) {
    return null;
  }

  const refercode = user?.referralCode;

  const handleCopyToClipboard = () => {
    var textToCopy = `Play Ludo and earn *₹10000* daily. *5% commission for all game (referral bonus 2%)* . https://kd124.com/login/${user?.referralCode} Register Now, My refer code is ${user?.referralCode}.`;

    if (WebSitesettings.isSignupBonusAllowed) {
      textToCopy = ` 👉Rs. 20/- deposited in your KSR account .* Current balance is Rs. 20.0 \n 👉Play Ludo and earn *₹10,000* daily.\n\n https://kd124.com/login/${user?.referralCode} \n\n Register Now, My refer code is ${user?.referralCode}.`;
    } else {
      textToCopy = ` Play Ludo and earn *₹10000* daily.\n *5% commission for all game (referral bonus 2%)* .\n\n https://kd124.com/login/${user?.referralCode} \n\n Register Now, My refer code is ${user?.referralCode}.`;
    }

    // const textToCopy = `Hi Friends, Your Rs.5️⃣0️⃣ FREE CASH is waiting. Download Indian App kd124 Play Ludo and earn ₹ 1 Lakhs Daily. 👉0% Commission (4PM - 8PM 👉Referral - 1% For All Games. https://kd124.com/login/${user?.referralCode} Register Now, My refer code is ${user?.referralCode}.`;

    const tempInput = document.createElement("textarea");
    tempInput.value = textToCopy;

    document.body.appendChild(tempInput);

    tempInput.select();
    tempInput.setSelectionRange(0, 99999);

    document.execCommand("copy");

    document.body.removeChild(tempInput);

    Swal.fire({
      position: "bottom",
      icon: "success",
      type: "success",
      title: "Text Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };

  return (
    <>
      {/* <Header user={user} /> */}
      <div>
        {/* style={{ height: "100vh" }} */}
        <div
          className="leftContainer"
          style={{ height: "100vh", paddingTop: "60px" }}
        >
          <div className="p-3 mt-1">
            <div>
              <div className="card" style={{ border: "1px solid #ccc" }}>
                <div
                  className="card-header text-center"
                  style={{
                    fontWeight: "500",
                    fontSize: "15px",
                    letterSpacing: "0.3px",
                    fontFamily: "Poppins",
                  }}
                >
                  Your Referral Earnings
                </div>
                <div className="card-body p-2">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex flex-column border-end flex-grow-1 align-items-center justify-content-center">
                      <span
                        className="text-capitalize fw-bold"
                        // style="font-size: 0.8rem;"
                        style={{ fontSize: "1rem", fontFamily: "Poppins" }}
                      >
                        referred players
                      </span>
                      <span style={{ fontSize: "1rem", fontFamily: "Poppins" }}>
                        {cardData && cardData}
                      </span>
                    </div>
                    <div className="d-flex flex-column align-items-center justify-content-center flex-grow-1">
                      <span
                        className="text-capitalize fw-bold"
                        // style="font-size: 0.8rem;"
                        style={{ fontSize: "1rem", fontFamily: "Poppins" }}
                      >
                        Referral Earning
                      </span>
                      <span style={{ fontSize: "1rem", fontFamily: "Poppins" }}>
                        ₹{user?.referralEarning}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-2">
              <div className="card" style={{ border: "1px solid #ccc" }}>
                <div
                  className="card-header text-center"
                  style={{
                    fontWeight: "500",
                    fontSize: "15px",
                    letterSpacing: "0.3px",
                    fontFamily: "Poppins",
                  }}
                >
                  Referral Code
                </div>
                <div className="card-body p-3">
                  <picture className="">
                    <img
                      alt="img"
                      // width="226px"
                      src={process.env.PUBLIC_URL + "Images/refer/refer1.png"}
                      className="snip-img w-75 mx-5"
                    />
                  </picture>
                  <div>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        disabled=""
                        value={user?.referralCode}
                        style={{
                          background: "#e9ecef",
                          border: "1px solid #e9ecef",
                          padding: "1.2rem",
                          height: "42px",
                        }}
                      />
                      <button
                        id="copyButton"
                        onClick={() => {
                          const button = document.getElementById("copyButton");

                          navigator.clipboard.writeText(refercode);
                          button.style.backgroundColor = "#2B7347";
                          button.querySelector("span").textContent =
                            "Copied!!!";

                          setTimeout(() => {
                            button.style.backgroundColor = "#0d6efd";
                            button.querySelector("span").textContent = "Copy";
                          }, 3000);
                        }}
                        className="btn  text-uppercase"
                        style={{ backgroundColor: "#0d6efd" }}
                      >
                        <span
                          style={{
                            fontFamily: "Poppins",
                            color: "#fff",
                            fontSize: "1.1rem",
                          }}
                        >
                          copy
                        </span>
                      </button>
                    </div>
                  </div>
                  <p
                    className="text-uppercase fw-bold fs-3 p-0 m-0 my-3 text-center"
                    style={{ fontFamily: "Poppins" }}
                  >
                    or
                  </p>
                  <div className="d-grid">
                    <a
                      target="_blank"
                      //                       href={`whatsapp://send?text=${encodeURIComponent(
                      //                         `Hey there!
                      // I’m inviting you to play Ludo Empire and win real cash with me.
                      // Sign-up using my referral code ${user?.referralCode}.
                      // Download now: https://kd124.com/login/${user?.referralCode}
                      // Message and data rates may apply.`
                      //                       )}`}
                      href={
                        WebSitesettings.isSignupBonusAllowed
                          ? `https://wa.me/?text=${encodeURIComponent(
                              `👉Rs. 20/- deposited in your KSR account .* Current balance is Rs. 20.0 \n` +
                                `👉Play Ludo and earn *₹10,000* daily.\n\n` +
                                `https://kd124.com/login/${user?.referralCode}\n\n` +
                                `Register Now, My refer code is *${user?.referralCode}*.`
                            )}`
                          : `https://wa.me/?text=${encodeURIComponent(
                              `Play Ludo and earn *₹10,000* daily.\n\n` +
                                `https://kd124.com/login/${user?.referralCode}\n\n` +
                                `Register Now, My refer code is *${user?.referralCode}*.`
                            )}`
                      }
                    >
                      <button
                        className="btn p-1 btn-md w-100"
                        style={{ backgroundColor: "#2B7347" }}
                      >
                        <svg
                          style={{ color: "#fff", fontFamily: "Poppins" }}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 16 16"
                          width="1rem"
                          height="1rem"
                          fill="currentColor"
                          className="me-2"
                        >
                          <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"></path>
                        </svg>
                        <span
                          className="text-capitalize"
                          style={{
                            color: "#fff",
                            fontFamily: "Poppins",
                            fontSize: "1rem",
                          }}
                        >
                          share on whatsapp
                        </span>
                      </button>
                    </a>
                  </div>
                  <div className="d-grid mt-2">
                    <button
                      className="btn p-1 btn-md w-100"
                      style={{ backgroundColor: "#6c757d" }}
                      onClick={handleCopyToClipboard}
                    >
                      <svg
                        style={{ color: "#fff", fontFamily: "Poppins" }}
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                        width="1rem"
                        height="1rem"
                        fill="currentColor"
                        className="me-2"
                      >
                        <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"></path>
                        <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"></path>
                      </svg>

                      <span
                        className="text-capitalize"
                        style={{
                          color: "#fff",
                          fontFamily: "Poppins",
                          fontSize: "1rem",
                        }}
                      >
                        copy to clipboard
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-2 mb-5">
              <div className="card" style={{ border: "1px solid #ccc" }}>
                <div
                  className="card-header text-center"
                  style={{
                    fontWeight: "500",
                    fontSize: "15px",
                    letterSpacing: "0.3px",
                    fontFamily: "Poppins",
                  }}
                >
                  How It Works
                </div>
                <div className="card-body p-2 ">
                  <ul className="m-0 p-0">
                    <li
                      className="text-center"
                      style={{
                        border: "1px solid rgba(0,0,0,0.13)",
                        listStyle: "none",
                        borderTopLeftRadius: "3px",
                        borderTopRightRadius: "3px",
                        paddingInline: "1rem",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                        fontSize: "1.1rem",
                        color: "#000",
                        fontFamily: "Poppins",
                      }}
                    >
                      You can refer and{" "}
                      <b style={{ fontFamily: "Poppins" }}>Earn 2%</b> of your
                      referral winning, every time
                    </li>
                    <li
                      className="text-center"
                      style={{
                        border: "1px solid rgba(0,0,0,0.13)",
                        listStyle: "none",
                        borderBottomLeftRadius: "3px",
                        borderBottomRightRadius: "3px",
                        paddingInline: "1rem",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                        fontSize: "1.1rem",
                        color: "#000",
                        fontFamily: "Poppins",
                      }}
                    >
                      Like if your player plays for{" "}
                      <b style={{ fontFamily: "Poppins" }}>₹10000</b> and wins,
                      You will get <b style={{ fontFamily: "Poppins" }}>₹200</b>{" "}
                      as referral amount.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Refer;
